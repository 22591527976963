@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";

.titlePosition {

}

.mainSection {
  margin: 0 auto;
  padding-top: 56px;
  padding-bottom: 150px;

  overflow: hidden;
  position: relative;
}

.wrapper {
  max-width: $container;
  margin: 0 auto;
}

.meduzOne {
  display: none;
}

.meduzOneRight {
  display: none;
}

@media (max-width: 1200px) {
  .mainSection {
    padding-left: 30px;
    padding-right: 30px;
    border-top: none;
  }

  .meduzOne {
    display: block;
    position: absolute;
    top: -2%;
    left: -12%;
  }

  .meduzOneRight {
    display: block;
    width: 114px;
    height: 159px;
    transform: scale(-1, 1);
    position: absolute;
    top: 23%;
    right: -4%;
  }
}

@media (max-width: 768px) {
  .mainSection {
    padding-right: 0;
  }

  .meduzOneRight {
    top: 28%;
  }
}

@media (max-width: 600px) {
  .meduzOne {
    height: 260px;
    top: 2%;
    left: -15%;
  }

  .meduzOneRight {
    top: 29%;
    right: -6%;
  }
}

@media (max-width: 600px) {
  .meduzOneRight {
    top: 30%;
  }
}

@media (max-width: 400px) {
  .meduzOneRight {
    top: 33%;
  }
}