@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";
@import "./../../../common/styles/animations";

.bigJellyfishBody {
  position: absolute;
  top: 50px;
  left: 830px;
  height: 420px;
  width: 420px;
}

.bigJellyfishHead {
  height: 100%;
  width: 100%;
  animation-duration: 120s;
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bigJellyfishTail {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 53%;
  left: 53%;
}

.pictOne {
  position: absolute;
  height: 39%;
  width: 39%;
  top: 7%;
  left: 67%;
}

.pictTwo {
  position: absolute;
  height: 39%;
  width: 39%;
  top: 35%;
  left: 20%;
}


@media (max-width: 1800px) {
  .bigJellyfishBody {
    height: 400px;
    width: 400px;
    top: 65px;
    left: 820px;
  }
}

@media (max-width: 1600px) {
  .bigJellyfishBody {
    height: 380px;
    width: 380px;
    top: 80px;
    left: 810px;
  }
}

@media (max-width: 1440px) {
  .bigJellyfishBody {
    height: 360px;
    width: 360px;
    top: 95px;
    left: 800px;
  }
}

@media(max-width: 1200px) {
  .bigJellyfishBody {
    height: 400px;
    width: 400px;
    top: 420px;
    left: 280px;
  }

  .pictOne {
    display: none;
  }

  .pictTwo {
    display: none;
  }
}

@media(max-width: 1000px) {
  .bigJellyfishBody {
    height: 360px;
    width: 360px;
    top: 420px;
    left: 200px;
  }
}

@media(max-width: 800px) {
  .bigJellyfishBody {
    height: 320px;
    width: 320px;
    top: 450px;
    left: 180px;
  }
}

@media (max-width: 600px) {
  .bigJellyfishBody {
    height: 280px;
    width: 280px;
    top: 310px;
    left: 80px;
  }
}

@media (max-width: 400px) {
  .bigJellyfishBody {
    height: 240px;
    width: 240px;
    top: 380px;
    left: 40px;
  }
}




