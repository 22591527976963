@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";

.titlePosition {

}


.mainContainer {
  width: $container;
  margin: 0 auto;
  user-select: none;
}
@media (max-width:1200px) {
  .mainContainer {
    width: auto;
  }
}