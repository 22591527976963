@import './../../common/styles/variables';
@import "./../../common/styles/mixins";
@import "./../../common/styles/animations";

.phoneNumberContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.imageContainer {
  width: 40px;
  height: 20px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../src/assets/graphics/footer/greece.webp');
  opacity: .7;
}

.UKFlag {
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../src/assets/graphics/footer/greece.webp');
  opacity: .7;
}

.flag {
  margin-right: 130px;
  height: 20px;
  width: 40px;
  opacity: .7;

  .blue,
  .black {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .yellowUkraine,
  .yellowGermany {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .blue {

    margin: 2px 0 0 0;
    height: 10px;
    background-color: #0057B7;
    opacity: 0.75;
  }

  .yellowUkraine {
    margin: 0;
    height: 10px;
    background-color: #FFDD00;
    opacity: 0.75;
  }

  .yellowGermany {
    margin: 0;
    height: 6.6px;
    background-color: #FFCC00;
    opacity: 0.75;
  }

  .black {
    height: 6.6px;
    background-color: #000000;
    opacity: 0.75;
    //margin: 0;
    margin: 3px 0 0 0;
  }

  .red {
    height: 6.6px;
    background-color: #DD0000;
    opacity: 0.75;
    margin: 0;
  }
}

.mainContainer {
  border-top: $border;
  background: $gradientBackground;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}

.wrapper {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}


.title {
  text-align: left;
  margin-bottom: 40px;
}

.contentContainer {
  @include text($fontFamilyManrope, 500, 24px, $textColorOne, 32.78px, medium);
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
}

.phoneNumber {
  margin-bottom: 10px;
  margin-right: 10px;
}

.phoneNumber > div > p {
  margin: 0;

}

.makeContactWithUs > div:first-child {
  margin-bottom: 20px;
}

.socialNetworks {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: baseline;
  max-width: 60px;

  & > div > a > img {
    filter: grayscale(.8);
    width: 50px;
    height: 50px;
    transition: .4s;

    &:hover {
      filter: grayscale(0);
      transform: scale(1.1, 1.1);
    }
  }

  &:not(:last-child),
  div {
    margin-right: 52px;
  }
}

.mainContainer::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $accentColorBlue;
  opacity: .3;
}

.bigJellyfishBody {
  position: absolute;
  top: 130px;
  right: 300px;
  height: 400px;
  width: 400px;
}

.bigJellyfishHead {
  height: 100%;
  width: 100%;
  animation-duration: 90s;
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bigJellyfishTail {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 53%;
  left: 53%;
}

.littleJellyfish {
  position: absolute;
  bottom: 25px;
  left: 23px;
  width: 320px;
}

@media (max-width: 1800px) {

  .bigJellyfishBody {
    height: 370px;
    width: 370px;
    top: 130px;
    right: 270px;
  }
}

@media (max-width: 1600px) {
  .bigJellyfishBody {
    height: 340px;
    width: 340px;
    top: 130px;
    right: 240px;
  }
}

@media (max-width: 1440px) {
  .bigJellyfishBody {
    height: 310px;
    width: 310px;
    top: 130px;
    right: 210px;
  }
}

@media(max-width: 1200px) {
  .phoneNumberContainer {
    justify-content: center;
  }
  .flag {
    margin-right: 0;
  }
  .germanyFlag, .UKFlag {
    margin-left: 2vw;
  }
  .ukraineFlag {
    margin-left: 3.5vw;
  }

  .mainContainer {
    background: $mobileGradientBackground;
  }

  .title {
    text-align: center;
    margin-bottom: 60px;
  }

  .contentContainer {
    display: block;
    text-align: center;
  }

  .phoneNumber {
    margin-bottom: 36px;
  }

  .socialNetworks {
    min-width: 100%;
    justify-content: center;
  }

  .bigJellyfishBody {
    height: 280px;
    width: 280px;
    top: 130px;
    right: 180px;
  }

  .littleJellyfish {
    top: 10px;
    width: 250px;
    height: 360px;
  }
}

@media (max-width: 768px) {
  .ukraineFlag {
    margin-left: 4vw;
  }

  .littleJellyfish {
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .phoneNumberContainer {
    align-items: center;

    .ukraineFlag {
      margin-left: 3.5vw;
    }

    .black {
      margin: 0;
    }

    .blue {
      margin: 0;
    }
  }
  .mainContainer {
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .title {
    margin-bottom: 30px;
  }

  .contentContainer {
    @include text($fontFamilyManrope, 500, 14px, $textColorOne, 19.12px, medium);
    margin-bottom: 29px;
  }

  .phoneNumber {
    margin-bottom: 20px;
  }

  .footerBtn {
    @include text($fontFamilyManrope, 500, 18px, $textColorOne, 19.33px, 0);
    max-width: 300px;
    width: 100%;
    height: 50px;
  }

  .socialNetworks {

    &:not(:last-child),
    div {
      margin-right: 24px;
    }
  }

  .socialNetworks > div > a > img:first-child {
    width: 32px;
    height: 30px;
  }

  .bigJellyfishBody {
    height: 250px;
    width: 250px;
    top: 130px;
    right: 150px;
  }
}

@media (max-width: 500px) {
  .bigJellyfishBody {
    height: 220px;
    width: 220px;
    top: 130px;
    right: 120px;
  }
}

//.imageContainer {
//  width: 40px;
//  height: 20px;
//
//  img {
//    border-radius: 5px;
//
//    width: 100%;
//    height: 100%;
//  }
//}