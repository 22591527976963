@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";

.body {
  width: 379px;
  margin: 0;
  padding: 0;
  position: relative;
  &:hover {
    .photo {
      filter: grayscale(0);
      transition: $transition;

    }
}

.photoContainer {
  width: 350px;
  height: 464px;

  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1);
  transition: 1s;

}


.name {
  @include text($fontFamilyManrope, 500, 24px, $textColorOne, 32.78px, medium);
  margin-top: 14px;
}

.profession {
  @include text($fontFamilyManrope, 300, 18px, $textColorOne, 21.94px, normal);
  margin-top: 10px;
}

.experience {
  @include text($fontFamilyManrope, 300, 13px, $textColorOne, 15.85px, normal);
  margin-top: 5px;
}

@media (max-width: 768px) {
  .body {
    width: 245px;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .name {
    @include text($fontFamilyManrope, 500, 14px, $textColorOne, 19.12px, medium);
    margin-top: 14px;
  }
  .profession {
    @include text($fontFamilyManrope, 300, 13px, $textColorOne, 15.85px, 0);
    margin-top: 10px;
  }
  .experience {
    @include text($fontFamilyManrope, 300, 11px, $textColorOne, 13.41px, normal);
    margin-top: 5px;
  }

  .photoContainer {
    max-width: 219px;
    max-height: 251px;
    margin-left: 8px;
  }

}