@import './../../common/styles/variables';
@import "./../../common/styles/mixins";
@import "./../../common/styles/animations";

.header {
  position: relative;
  z-index: 5000;
  height: 99px;
  border-bottom: 1px solid $accentColorBlack;
  width: 100%;
  transition: .6s;
  background-color: $accentColorWhite;
}

.stickyHeader {
  animation: show .6s ;
  height: 80px;
  transition: .6s;

  position: fixed;
  z-index: 1000000;
  background: linear-gradient( to bottom left, white 50%, $accentColorBlue ) ;
  .itemLogo img {
    height: 50px;
  }
}

.unStickyHeader{
}

.headerContainer {
  z-index: 40;
  max-width: 1920px;
  padding: 0 65px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;

}

.burgerBtn {
  display: none;
  margin-right: 0;
  width: 60px;
  height: 60px;
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
}

.burgerLine {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 40px;
  height: 5px;
  background-color: $accentColorBlue;
}

.burgerLine::after,
.burgerLine::before {
  content: '';
  position: absolute;
  left: 0;
  width: 40px;
  height: 5px;
  background-color: $accentColorBlue
}

.burgerLine::after {
  transform: translateY(14px);
}

.burgerLine::before {
  transform: translateY(-14px);
}

.navigationContainer {
  position: relative;
  z-index: 105;
  display: flex;
}

.itemLogo {
  //width: 100%;
  //height: inherit;
  //width: 150px;
  padding: 10px 0;
  & > img {
    object-fit: contain;
    height: 80px;
    width: 80%;
  }
}

.itemMenu {
  @include text($fontFamilyManrope, 500, 18px, $textColorOne, 19px, normal);
  cursor: pointer;
  padding: 23px ;
  border: .7px solid transparent;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 78px;
  }

  &:hover {
    border: $border;
  }

  &:active {
    background: $accentColorBlue;
    box-shadow: inset 1px 1px 5px rgba(24, 57, 106, 0.2);
  }
}

@media (max-width: 1800px) {
  .headerContainer {
    padding: 0 30px;
  }
  .itemMenu {
    //padding: 20px ;

    &:not(:last-child) {
      margin-right: 38px;
    }
  }

}

@media (max-width: 1440px) {
  .itemMenu {
    @include text($fontFamilyManrope, 400, 16px, $textColorOne, 19px, normal);
    padding: 15px ;
  }
}

@media (max-width: 1200px) {
  .header {
    height: 59px;
  }

  .itemLogo {
    //width: 100px;
    //padding: 10px 0;
    & > img {
      object-fit: contain;
      height: 50px;
      width: 100%;
    }
  }

  .headerContainer {
    padding: 0 35px;
  }

  .navigationContainer {
    position: fixed;
    top: 59px;
    right: 0;
    bottom: 0;
    transition: $transition;
    left: 50%;
    transform: translateX(110%);
    overflow-x: hidden;
    display: block;
    //transition: .4s;
  }
  .burgerBtn {
    display: block;
  }
  .itemMenu {
    @include text($fontFamilyManrope, 300, 24px, $textColorOne, 29px, normal);
    padding-left: 5vw;
    text-align: center;
    height: 10vh;

    &:hover {
      border: none;
      border-bottom: .7px solid $textColorOne;
      border-left: .7px solid rgba(0, 0, 0, 0);
      border-top: .7px solid $textColorOne;

      &:first-child {
        border-top: .7px solid rgba(0, 0, 0, 0);
      }
    }

    &:active {
      //box-shadow: inset 1px 1px 5px white;

    }

    &:not(:last-child) {
      margin-right: 0;
    }
  }
}


.navigationContainer.active {
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transform: translateX(0);
  background-color: $accentColorBlue;
  border-left: $border;
  position: fixed;
  //transition: .4s;
  bottom: 0;
  //top: 99px;
  left: 50%;
  z-index: 1000;
}

.burgerBackground {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  background-color: $backgroundColorBlue;
  opacity: .6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.navigationSidebar {
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transform: translateX(0);
  background-color: $accentColorBlue;
  border-left: $border;
  position: fixed;
  left: 55%;
  z-index: 1000;
}

//.header .activeMenu {
//  overflow-y: hidden;
//}

@media (max-width: 768px) {
  .navigationSidebar {
    left: 45%;
  }
}

@media (max-width: 600px) {
  .itemMenu {
    @include text($fontFamilyManrope, 500, 20px, $textColorOne, 25px, normal);
    padding-left: 5vw;
  }
  .navigationSidebar {
    left: 35%;
  }
}