@import './../../common/styles/variables';
@import "./../../common/styles/mixins";
@import "./../../common/styles/animations";

.openedBurger {
  //overflow-y: hidden;
  position: relative;
  transition: $transition;
  opacity: 1;
}

.openedBurger:after {
  content: '';
  position: absolute;
  overflow: hidden;
  z-index: 1;
  height: 100%;
  background-color: $backgroundColorBlue;
  opacity: .9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.closeBurger {
  position: static;
}

.main {
  //  min-height: 770px;
  background: $gradientBackground;
  width: 100%;
  overflow-x: hidden;

}

.mainContainer {
  position: relative;
  max-width: 1150px;
  width: 100%;
  min-width: 690px;
  height: 100%;
  margin: 0 auto;
  // box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.listContainer {
  margin-top: 124px;
}

.mainSubContainer {
  width: 100%;
}

.textOne {
  @include text($fontFamilyManrope, 500, 42px, $textColorOne, 47px, 1px);
  max-width: 820px;
  width: 100%;
  margin-top: 121px;
}

.secondPartOfTitle {
  margin-top: 0;
  font-size: 30px;
}

.textTwo {
  @include text($fontFamilyMontserrat, 300, 18px, $textColorOne, 22px, normal);
  max-width: 730px;
  width: 100%;
  margin-top: 10px;
}

.textThree {
  @include text($fontFamilyManrope, 500, 36px, $textColorOne, 47px, 1px);
  max-width: 820px;
  width: 100%;
  margin-bottom: 50px;
}

.containerTextList {
  margin-left: auto;
  margin-bottom: 90px;
  max-width: 900px;
  width: 100%;
  min-width: 690px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.textListOne {
  @include text($fontFamilyManrope, 500, 18px, $textColorOne, 25px, normal);
  max-width: 300px;
  width: 25%;
  box-sizing: border-box;
  padding-left: 30px;
  height: 70px;
  border-left: 1px solid black;
}

.textListFirst:nth-last-child(-n + 4) {
  & > span {
    display: block;
    margin-top: 2.5em;
  }
}

.meduzOne {
  top: 300px;
  left: 20px;
  position: absolute;
}

.bigJellyfishHead {
  display: none;
  transform-origin: center;
  animation-name: spin;
  animation-duration: 90s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@media (max-width: 1440px) {
  .mainSubContainer {
    padding: 0 50px;
  }
  .textOne {
    @include text($fontFamilyManrope, 500, 30px, $textColorOne, 40px, 1px);
    width: 70%;
    margin-top: 140px;
  }
  .textTwo {
    @include text($fontFamilyMontserrat, 300, 16px, $textColorOne, 20px, normal);
    margin-top: 8px;
  }
  .meduzOne {
    height: 400px;
    width: 256px;
    top: 410px;
  }
}

@media (max-width: 1200px) {
  .main {
    padding: 60px 20px 200px;
    height: 100%;
    position: relative;
    overflow-y: hidden;
    background: $mobileGradientBackground;
  }
  .mainContainer {
    display: block;
    margin: 0 auto;
    position: static;
    min-width: 0;
  }
  .mainSubContainer {
    padding: 0;
  }

  .textOne {
    min-width: 100%;
    margin: 0;

  }
  .secondPartOfTitle {
    margin-top: 1vh;
    font-size: 24px;
  }
  .textTwo {
    margin-top: 3vh;
    min-width: 100%;
  }
  .textThree {
    margin-top: 5vh;
  }
  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin: 0;
    margin: 70px 0 0 0;
    text-align: center;
  }

  .containerTextList {
    margin-left: 0;
  }
  .textListFirst {
    border-right: .7px solid $textColorOne;
  }
  .textListOne {
    border: none;
    height: inherit;
    margin: 0;
    min-width: 50%;
    padding: 0;

    &:nth-child(odd) {
      border-right: .7px solid $textColorOne;
    }
  }
  .textListOne {
    padding-bottom: 30px;

  }
  .meduzOne {
    display: none;
  }
  .meduzTwo {
    display: none;
  }
  .bigJellyfishHead {
    display: block;
    position: absolute;
    top: 56%;
    bottom: 0;
    left: 236px;
    transform: rotateZ(351deg)
  }
  .bigJellyfishTail {
    position: absolute;
  }
}

@media (max-width: 1000px) {
  .main {
    padding: 60px 20px 240px;
  }
}

@media (max-width: 800px) {
  .main {
    padding: 60px 20px 220px;
  }
}

@media (max-width: 600px) {
  .main {
    padding: 60px 20px 200px;
  }

  .textOne {
    @include text($fontFamilyManrope, 500, 20px, $textColorOne, 26px, 1px);
    margin-bottom: 10px;
  }
  .secondPartOfTitle {
    font-size: 18px;
  }
  .textTwo {
    @include text($fontFamilyManrope, 300, 14px, $textColorOne, 17px, 1px);
    margin-top: 3vh;
  }
  .listContainer {
    margin-top: 50px;
  }
  .textThree {
    @include text($fontFamilyManrope, 500, 20px, $textColorOne, 27.32px, normal);
    text-align: center;
    margin-bottom: 21px;
  }
  .textListOne {
    @include text($fontFamilyManrope, 500, 14px, $textColorOne, 19px, normal);
    padding-bottom: 20px;
  }
  .containerTextList {
    min-width: 0;
    margin-bottom: 0;
  }
}