.item {
  box-shadow: 0 0 10px #CCDFFB;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.4s;
  position: relative;

  display: flex;
  flex-direction: column;

  &[data-isOpen="true"] {
    background-color: #fff;
    z-index: 100;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }

}

.item_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1200px) {
    display: flex;
  }
}

.date {
  font-size: 0.7em;

  @media (min-width: 1200px) {
    font-size: 1em;
  }
}

.star_icon {
  height: 20px;
  width: 20px;

  @media (min-width: 768px) {
    height: 15px;
    width: 15px;
  }

  @media (min-width: 1200px) {
    height: 15px;
    width: 15px;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.message {
  overflow: hidden;
  font-size: 1em;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* количество строк */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  @media (min-width: 768px) {
    font-size: 1.5em;
  }
}

.main_content > p,
.additional_topic p {
  margin: 0;
}

.main_content > p {
  margin-bottom: 20px;
}

.author {
  font-size: 1.3em;
}

.title {
  font-size: 1.5em;

  @media (min-width: 768px) {
    font-size: 2em;
  }
}

.additional_topic {
  div {
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-size: 1.2em;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    @media (min-width: 768px) {
      width: 45%;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

}