@import './variables';
@import "./mixins";

* {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
}


.title {
  @include text($fontFamilyManrope, 600, 36px, $textColorOne, 49px, 1px);
  text-align: center;
}

.departmentTitle {
  @include text($fontFamilyManrope, 500, 24px, $textColorOne, 32.78px, 1px);
  margin: 50px 0;
}

// button for contact (footer, portfolio)
.contactWithUsBtn {
  @include text($fontFamilyManrope, 500, 18px, $textColorOne, 19.33px, normal);
  cursor: pointer;
  width: 446px;
  height: 55px;
  border: none;
  background-color: $accentColorBlue;
  box-shadow: inset 1px 1px 5px rgba(24, 57, 106, 0.2);
  &:hover {
    background-color: #fff;
    border: $border;
    transition: .4s;
  }
}
// button with arrow
.arrowButton {

  border: none;
  cursor: pointer;
  padding:  5px 17px;
  background-color: transparent;

  position: relative;

.arrowButtonLine {
  width: .7px;
  height: 100%;
  position: absolute;
  top: 0;
  //right: 49.35%;
  background-color: $textColorOne;

}

}

.arrowButton::before {
  content: '';
  width: .7px;
  height: 5px;
  position: absolute;
  background-color: $textColorOne;
  bottom: -10%;
  left: 48%;
  transform:  rotate(-30deg);

}
.arrowButton::after {
  content: '';
  width: .7px;
  height: 5px;
  position: absolute;
  background-color: $textColorOne;
  bottom: -10%;
  right: 48%;
  transform:  rotate(30deg);
}

@media (max-width: 600px) {
  .departmentTitle {
    @include text($fontFamilyManrope, 500, 14px, $textColorOne, 19.12px, 1px);
    margin: 20px 0;
  }
  .contactWithUsBtn {
    width: 100%;
  }
}