@import './../../common/styles/variables';
@import "./../../common/styles/mixins";
@import "./../../common/styles/animations";



.str {
  margin-left: 1vw;
  animation: showOpacity 1s ;
}

.objectSimulate {
  animation:  showOpacity 1s;

  font-size: 1.5em;
  font-family: monospace;

  position: absolute;
  top: 0;
  left: 2%;
}

.requestContainer {
  position: relative;
  background-color: rgba(232, 241, 253, .45);
  padding: 100px 0;
}

.title {
  margin-bottom: 65px;
}

.form {
  display: flex;
  justify-content: center;
  transition: $transition;
}


.activeForm {
  padding-left: 20vw;
}


.inputContainer {
  margin-right: 36px;
}

.inputField, .fileInput {
  z-index: 1;
  position: relative;
  outline: none;
  border: 1px solid transparent;
  transition: $transition;

  height: 55px;
  width: 235px;
  padding: 17px 28px;

  font-size: 18px;
  font-weight: 500;

  background-color: rgba(204, 223, 251, 0.45);
  box-shadow: inset 1px 1px 5px rgba(24, 57, 106, 0.2);

  &[placeholder], .fileInput {
    font-size: 18px;
    line-height: 22px;
  }

  &:focus, &:hover {
    box-shadow: inset 2px 2px 10px rgba(24, 57, 106, 0.2);
  }

  &:focus:invalid {
    border-color: red;
  }

  &:focus:valid {
    border-color: green;
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: -15px;
    left: -28px;
    right: -28px;
    bottom: -15px;
    cursor: pointer;
  }
}

label {
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  max-width: inherit;
  
  span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;

  }

  img {
    margin-right: 1vw;
  }
}

.requestButton {
  border: none;
  text-align: left;
  padding: 17px 28px;
  cursor: pointer;

  color: white;
  font-size: 18px;
  line-height: 22px;
  background-color: #7B61FF;

  width: 300px;

  transition: $transition;

  &:hover {
    transition: $transition;
    padding-left: 50px;
  }
}

@media (max-width: 1440px) {
  .objectSimulate {
    top: -25%;
  }
  .activeForm {
    padding-left: 0;
  }
}

@media (max-width: 1200px) {

  .form {
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }
  .inputContainer {
    width: 50%;
    margin-right: 0;
    margin-bottom: 1vh;
  }
  .requestButton {
    width: 50%;
    margin-right: 0;
    margin-top: 2vh;
    text-align: center;
  }

  .inputField {
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .inputContainer, .requestButton {
    width: 80%;
    text-align: center;
  }
}