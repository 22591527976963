@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";
@import "./../../../common/styles/animations";

.forBackground {
  position: relative;
}

.mainContainer {
  z-index: 1;
  padding-top: 56px;
  padding-bottom: 50px;

  margin: 0 auto;
  max-width: 1109px;
  position: relative;
  transition: $transition;
}


.titlePosition {
  margin-bottom: 50px;
}

.technologyCardInformation {
  margin-top: 20px;
  height: 110px;
  animation-name: show;
  animation-duration: .5s;
}

.meduzOne {
  //display: block;
  position: absolute;
  width: 226px;
  top: 412px;
  left: 128px;
  transform: rotate(350deg);
  opacity: .7;
}

.meduzOneRight {
  position: absolute;
  transform: scale(-1, 1);
  width: 294px;
  top: 1093px;
  right: 23px;
  opacity: .7;
}

@media (max-width: 1440px) {
  .meduzOne {
    display: none;
  }

  .meduzOneRight {
    display: none;
  }
}

@media (max-width: 1200px) {
  .mainContainer {
    padding: 100px 40px;
    margin: 0;
    min-width: 100%;
  }

  .loadMoreBtnContainer {
    margin-top: 33px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .technologyCardInformation {
    margin-top: 13px;
    height: 50px;
  }

  .titleWrapper {
    margin-bottom: 63px;
  }
}
