@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";
@import "./../../../common/styles/animations";

text {
  transition: .6s;
}

.title {
  @include text($fontFamilyManrope, 500, 24px, $textColorOne, auto, normal);
  @include listItem(block, start, 0px 0px, 0px 0 0 0);
  position: relative;
  overflow: hidden;
  z-index: 10;
  height: 110px;
  transition: 400ms;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  &::after {
    content: "↓";
    text-align: center;
    transition: all .6s;
  }
}

.title__active {
  &::after {
    transform: rotate(-180deg);
  }
}

.title__text {
  @include text($fontFamilyManrope, 500, 24px, $textColorOne, 25.78px, normal);
}

@media (max-width: 768px) {
  .title {
    @include text($fontFamilyManrope, 500, 14px, $textColorOne, 1, normal);
    height: 50px;
    padding: 0 15px;
  }

  .title__text {
    @include text($fontFamilyManrope, 500, 14px, $textColorOne, 1, normal);
  }
}
//
//@media (max-width: 600px) {
//  .title {
//    //min-width: 290px;
//
//  }
//}

.tab {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  animation-name: show;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.tabContent {
  height: 0;
  position: relative;
  top: -1px;
  box-sizing: border-box;
  background: #ffffff;
  transition: .6s;
  opacity: 0;
  transform: translateY(-100%);
  border-left: .7px solid black;
  border-right: .7px solid black;
  border-bottom: .7px solid black;
  transition-timing-function: linear;

}

.tabContentActive {
  transition-timing-function: linear;
  transition: .6s;
  transform: translateY(0);
  //height: 350px;
  opacity: 1;
}

@media (max-width: 1200px) {
  .tabContentActive {
    & > div > svg{
      transform: translateY(10%);
    }

  }
}

@media (max-width: 768px) {
  .tabContentActive {
    & > div {
      transform: translateY(25%);
    }

  }
  .tab {
    margin-top: 10px;
  }
}

@media (max-width: 400px) {
  .tabContentActive {
    height: 150px;
  }
}