@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";
@import "./../../../common/styles/animations";


.bigJellyfishHead {
  position: absolute;
  top: -500px;
  right: -500px;
  width: 1200px;
  animation-name: spin;
  animation-duration: 100s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}



.introduceBox {
  //display: flex;
  margin-top: 50px;
  padding-bottom: 150px;

  .introduceText {
    @include text($fontFamilyMontserrat, 300, 18px, $textColorOne, 21.94px, normal);
    max-width: 585px;
    margin-right: 172px;
  }
  .secondary {
    margin-top: 25px;
  }
  .introducePhotoContainer {
    width: 145px;
    height: 195px;

    .introducePhoto {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.kate {
  width: 191px;
  height: 255px;
  position: absolute;
  top: 61px;
  left: 111px;
}

.artem {
  width: 146px;
  height: 194px;
  position: absolute;
  top: 355px;
  right: 350px;
}

.sergey {
  width: 171px;
  height: 227px;
  position: absolute;
  top: 33px;
  right: 71px;
}

@media (max-width: 1800px) {
  .kate {
    right: 71px;
    top: 33px;
  }

  .artem {
    right: 300px;
  }
}

@media (max-width: 1600px) {
  .kate {
    display: none;
  }

  .artem {
    right: 215px;
  }
}

@media (max-width: 1440px) {
  .artem {
    right: 130px;
    top: 450px;
  }
}

@media (max-width: 1200px) {
  .introduceBox {
    display: block;
    padding-bottom: 100px;
    padding-right: 20px;
  }

  .introduceText {
    min-width: 100%;
  }

  .introducePhotoContainer,
  .kate,
  .artem,
  .sergey {
    display: none;
  }
  .bigJellyfishHead {
    //width: 800px;
    top: 700px;
    right: -20%;
  }
}