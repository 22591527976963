@import "./variables";

@mixin text ($font-family, $font-weight, $font-size, $color, $line-height, $letter-spacing) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  color: $color;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  padding: 0;
  margin: 0;
};

@mixin listItem($display, $align-items, $padding, $margin ) {
  display: $display;
  align-items: $align-items;
  padding: $padding;
  margin: $margin;
  border: .7px solid $textColorOne;
}
