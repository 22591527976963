@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";
@import "./../../../common/styles/animations";

.bigJellyfishBody {
  position: absolute;
  top: 130px;
  right: 300px;
  height: 400px;
  width: 400px;
}

.bigJellyfishHead {
  height: 100%;
  width: 100%;
  animation-duration: 120s;
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bigJellyfishTail {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 53%;
  left: 53%;
}


@media (max-width: 1800px) {
  .bigJellyfishBody {
    height: 370px;
    width: 370px;
    top: 130px;
    right: 270px;
  }
}

@media (max-width: 1600px) {
  .bigJellyfishBody {
    height: 340px;
    width: 340px;
    top: 130px;
    right: 240px;
  }
}

@media (max-width: 1440px) {
  .bigJellyfishBody {
    height: 310px;
    width: 310px;
    top: 130px;
    right: 210px;
  }
}

@media(max-width: 1200px) {
  .bigJellyfishBody {
    height: 280px;
    width: 280px;
    top: 130px;
    right: 180px;
  }
}

@media (max-width: 600px) {
  .bigJellyfishBody {
    height: 250px;
    width: 250px;
    top: 130px;
    right: 150px;
  }
}

@media (max-width: 500px) {
  .bigJellyfishBody {
    height: 220px;
    width: 220px;
    top: 130px;
    right: 120px;
  }
}