@keyframes show {
  from {
    opacity: 0;
    transform: translateY(-200%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.loadMoreBtnContainer {
  display: none;
}


@keyframes spin {
  from {
    transform: rotate(-360deg);
  }
to {
  transform: rotate(0deg);
  }
}


@keyframes spinReverse {
  from {
    transform: rotate(360deg);
  }
to {
  transform: rotate(0deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    fill: blue;
  }
to {
  opacity: 1;
  fill: darkorchid;
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-15%);
  }
100% {
  opacity: 1;
  transform: translateX(0);
  }
}

@keyframes showOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}