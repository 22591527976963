@import './../../common/styles/variables';
@import "./../../common/styles/mixins";



//.servicesItem {
//  @include listItem(block, center, 45px 21px, 25px 15px);
//  box-sizing: border-box;
//
//  width: 50%;
//  max-width: 540px;
//  transition-duration: .1s;
//  transition: .5s;
//  background-color: white;
//  position: relative;
//
//
//
//
//  * {
//    margin: 0;
//  }
//
//  &:hover {
//    //border-bottom-color: transparent;
//
//    .servicesItemDescription {
//      opacity: 1;
//      transition: .6s;
//      visibility: visible;
//      height: 210px;
//    }
//  }
//
//}
//
//.servicesItemDescription,
//.activeDescription {
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  visibility: hidden;
//  z-index: 1;
//  border-bottom: $border;
//  border-left: $border;
//  border-right: $border;
//  padding-bottom: 25px;
//  opacity: 0;
//  & > a {
//    margin-left:  auto;
//    margin-right:  auto;
//
//  }
//}
//
//.servicesItemDescription {
//  position: absolute;
//  top: 75%;
//  left: -1px;
//  right: -1px;
//  background-color: #fff;
//
//  & > p {
//    padding-right: 7px;
//    padding-left: 66px;
//    margin-bottom: 62px;
//  }
//
//}
//
//
//
//@media (max-width: 1200px) {
//  .servicesItem {
//    height: 120px;
//    border-bottom-color: black;
//    margin: 0;
//    min-width: 100%;
//
//
//    &:not(:last-child) {
//      margin-bottom: 30px;
//    }
//  }
//
//  //.servicesItem.activeItem {
//  //  height: 290px;
//  //}
//
//  .servicesItemDescription,
//  .activeDescription {
//    & > a {
//      margin-top: 5vh;
//    }
//  }
//
//  .activeDescription,
//  .passiveDescription {
//
//    & > p {
//      padding-left: 45px;
//    }
//  }
//
//  .passiveDescription {
//    visibility: hidden;
//    //transform: translateY(-100%);
//  }
//  .activeDescription {
//    margin-top: 45px;
//    transition: .6s;
//    transition-delay: .1s;
//    align-items: start;
//    //transform: translateY(0);
//    visibility: visible;
//    border: none;
//    opacity: 1;
//
//    //border: none;
//    //position: static;
//    //opacity: 1;
//  }
//  .serviceOrderButton {
//    margin: 5vh auto 0;
//  }
//}


//@media (max-width: 400px) {
//  .servicesItem.activeItem {
//    height: 350px;
//  }
//}











//=========================================


.serviceItem__body {
  height: 120px;
  width: 50%;
  max-width: 540px;
  box-sizing: border-box;
  display: block;
  align-items: center;
  margin: 15px 15px;
}


.serviceItem__content {
  @include listItem(block, center, 45px 21px, 0);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition-duration: .1s;
  transition: all .5s;
  background-color: $accentColorWhite;
  position: relative;
  z-index: 10;
  overflow: hidden;
}


.serviceItem__contentAnimation {
  z-index: 50;
}

.serviceItem__contentActive {
  height: 270px;
  z-index: 100;
}


.serviceItem__title {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.serviceItem__logo {
  margin-right: 17px;
  height: 30px;
}

.servicesItem__titleText {
  @include text($fontFamilyManrope, 500, 24px, $textColorOne, 25.78px, normal);
  height: 30px;
}

.serviceItem__description {
  opacity: 0;
  transition: all .2s;
  min-height: 100%;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.serviceItem__descriptionActive {
  opacity: 1;
  height: 100%;
}

.serviceItem__descriptionText {
  width: 100%;
}

.serviceItem__button {
  color: black;
  text-decoration: none;
  width: 150px;
  height: 45px;
  border: none;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-color: $accentColorBlue;
  cursor: pointer;
  font-size: 18px;
  transition: .6s;
  &:hover {
    background-color: $accentColorWhite;
    border: $border;
  }
}


@media (max-width: 1200px) {
  .serviceItem__body {
    margin-left: 0;
    height: auto;
    width: 100%;
    max-width: 1200px;
  }

  .serviceItem__content {
    height: 120px;
  }

  .serviceItem__contentActive {
    height: 260px;
  }
}

@media (max-width: 768px) {
  .serviceItem__body {
    height: auto;
    width: 100%;
    max-width: 1200px;
  }

  .serviceItem__content {
    height: 50px;
    padding: 12px 0 8px 15px;
  }

  .serviceItem__contentActive {
    height: 250px;
  }

  .servicesItem__titleText {
    @include text($fontFamilyManrope, 500, 16px, $textColorOne, 15.04px, medium);
    height: 16px;
  }
  .serviceItem__description {
    justify-content: space-between;
  }
  .serviceItem__descriptionText {
    margin-top: 5%;
  }

  .serviceItem__descriptionActive {
    padding-bottom: 15px;
  }
  .serviceItem__button {
    margin-bottom: 5%;
  }

}

@media (max-width: 500px) {
  .serviceItem__button {
    width: 135px;
    height: 30px;
    font-size: 14px;
    margin-bottom: 10%;

  }
}