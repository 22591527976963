*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background-color: #E8F1FD;
}

*::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 10px;
    border: .7px solid black;
}

.MuiAlert-icon {
    color: white !important;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none;
}

.swiper-slide {
  transition: 1s;
}

.forSwiper > .swiper > .swiper-wrapper > .swiper-slide-active  {
   cursor: pointer;
   height: 300px;
   padding-top: 50px;
}

.stickyHeader {
    padding-top: 100px;
}


@media (max-width: 768px) {
    .forSwiper > .swiper > .swiper-wrapper > .swiper-slide-active {
        /*padding-top: 100px;*/
        height: 250px;
    }

}
@media (max-width: 600px) {
    .forSwiper > .swiper > .swiper-wrapper > .swiper-slide-active {
        padding-top: 0;
        height: 200px;
    }
    /*a > .swiper > .swiper-wrapper > .swiper-slide-active.openMobileCard  {*/
    /*    !*transform-box: border-box ;*!*/
    /*    transform: scale(150%, 150% );*/
    /*    height: 500px;*/
    /*    width: 400px;*/
    /*}*/
}
.on {
  position: fixed;
  top: 0;
  bottom: inherit;
  left: 0;
  right: 0;
  /*background-color: red;*/
  overflow: hidden;
}
