.btn {
  font-size: 20px;
  display: none !important;
  position: absolute !important;
  top: -30px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border:  0.7px solid;
  text-align: center;
  cursor: pointer;
  z-index: 10;

  @media (min-width: 768px) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5px;
    aspect-ratio: 3;
    right: 0
  }

  @media (min-width: 1200px) {
    aspect-ratio: 2;
    padding: 10px;
  }

  &::after {
    content: "↓";
    text-align: center;
    transform: rotate(-90deg)
  }
}

.prev {
  &::after {
   transform: rotate(-270deg);
  }

  @media (min-width: 768px) {
    right: 50px;
  }

  @media (min-width: 1200px) {
    right: 70px;

  }
}
