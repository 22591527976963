@import './../../../common/styles/variables';
@import "./../../../common/styles/mixins";

.carouselContainer {
  overflow: hidden;
  text-align: center;
}

.swiperWrapper {
  overflow: hidden;
  & > div {
    display: flex;
  }
  .slide > img {
    width: 350px;
    max-height: 466px;
    object-fit: cover;
  }
}

@media (max-width: 768px ) {
  .swiperWrapper {
    .slide > img {
      width: 219px;
      height: 251px;
      object-fit: cover;
    }

  }
}

