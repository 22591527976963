$backgroundColorPrimary: #b0ff9e;
$backgroundColorSecondary: #E8F1FD;

$fontFamilyManrope: 'Manrope', sans-serif;
$fontFamilyMontserrat: 'Montserrat', sans-serif;

$accentColorBlack: #000000;
$accentColorWhite: #FFFFFF;
$accentColorBlue: #CCDFFB;

$textColorOne: #000000;
$textColorTwo: #E8F1FD;
$textColorDarkBlue: #4179BA;
$textColorLightBlue: #0AA9CC;


$backgroundColorBlue: #E8F1FD;
$backgroundColorWhite: #FFFFFF;
$backgroundColorButtonRequest: #7B61FF;
$backgroundColorGray: #E5E5E5;

$gradientBackground: radial-gradient(90% 35%, $accentColorWhite, $textColorTwo);
$mobileGradientBackground: radial-gradient(35% 90%, $accentColorWhite, $textColorTwo);

$container: 1109px;

$border: .7px solid $textColorOne;
$transition : 1s;