@import './../../common/styles/variables';
@import "./../../common/styles/mixins";

//.services.stickyHeader {
//  padding-top: 136px;
//}

.closeBurger {
  position: static;
}

.services {
  background-color: $backgroundColorWhite;
  position: relative;
  padding-top: 56px;
  padding-bottom: 150px;
  overflow-x: clip;
}

.servicesContainer {
  z-index: 1;
  position: relative;
  max-width: 1150px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.titlePosition {
  width: 100%;
  text-align: center;
}

.itemContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.bigJellyfishTail {
  display: none;
}

.meduzOne {
  position: absolute;
  top: 66%;
  left: 26px;
}

.textOne {
  @include text($fontFamilyMontserrat, 300, 24px, $textColorOne, 29px, normal);
  margin-top: 11px;
}

.textTwo {
  width: 100%;
  text-align: center;
  @include text($fontFamilyManrope, 300, 17vw, $textColorTwo, 0.7, normal);

  @media screen and (min-width: 1150px) {
    @include text($fontFamilyManrope, 300, 180px, $textColorTwo, 0.7, normal);
  }

  @media screen and (max-width: 300px) {
    @include text($fontFamilyManrope, 300, 51px, $textColorTwo, 0.7, normal);
    height: 51px;
  }
}

@media (max-width: 1200px) {

  .services {
    padding-bottom: 150px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .services::after {
    content: '';
    position: static;
  }

  .itemContainer {
    display: block;
  }

  .servicesContainer {
    display: block;
  }

  .textOne {
    text-align: center
  }

  .textTwo {
    @include text($fontFamilyManrope, 300, 17vw, $textColorTwo, 0.7, -10px);
    margin-bottom: 30px;
  }

  .bigJellyfishTail {
    display: block;
    position: absolute;
    //top: -10%;
    top: -153px;
    left: 41%;
  }

  .meduzOne {
    top: 83%;
  }
}

@media (max-width: 1024px) {

  .bigJellyfishTail {
    left: 48%;
  }
}

@media (max-width: 880px) {

  .bigJellyfishTail {
    width: 395px;
    overflow-x: hidden;
  }
}

@media (max-width: 768px) {

  .textOne {
    @include text($fontFamilyMontserrat, 300, 14px, $textColorOne, 17.07px, 1px);
    margin-top: 11px;
  }

  .meduzOne {
    top: 73%;
    left: -132px;
  }

  .bigJellyfishTail {
    //top: -10px;
    left: 35%;
  }

}

@media (max-width: 600px) {

  .services {
    padding-bottom: 100px;
  }

  .bigJellyfishTail {
    width: 265px;
    //top: -9%;
    left: 33%;
    top: -110px;
  }

}

@media (max-width: 500px) {

  .textTwo {
    @include text($fontFamilyMontserrat, 300, 48px, $textColorTwo, 65.57px, -2px);
  }

  .bigJellyfishTail {
    //top: -10%;
    left: 32%;
  }

}

@media (max-width: 400px) {

  .bigJellyfishTail {
    width: 230px;
    //top: -11%;
    left: 37%;
  }

}