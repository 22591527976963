.container {
  font-family: "Manrope", sans-serif;
  height: 100vh;
  background: radial-gradient(white, rgba(204, 223, 251, 0.5));
  padding: 20px;

  @media (min-width: 768px) {
    padding: 20px 40px;
  }
}

.section_title,
.sub_title {
  padding: 0;
  text-align: center;
  margin-top: 0;
}

.section_title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  letter-spacing: 1px;
}

.sub_title {
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 1px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;

  }
}

.content {
  display: flex;
  height: calc(100vh - 200px);

  & > div {
    min-height: 100%;
  }

}

.cards {
  position: relative;
  height: inherit;
  padding: 20px;
  box-shadow: 0 0 20px #E5E5E5;
  background-color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @media (min-width: 1200px) {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 3;
  }
}


